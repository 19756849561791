<template>
    <div>
        <ValidationObserver ref="formModalValidate">
            <b-row>
                <div class="col-12" :label="$t('name')">
                    <ValidationProvider name="name" rules="required" v-slot="{ errors }">
                        <b-form-group :label="$t('name')">
                            <b-form-input :class="errors[0] ? 'is-invalid' : ''" v-model="form.name" />
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]" />
                        </b-form-group>
                    </ValidationProvider>
                </div>
                <div class="col-12">
                    <ValidationProvider name="code" rules="required" v-slot="{ errors }">
                        <b-form-group :label="$t('code')">
                            <b-form-input :class="errors[0] ? 'is-invalid' : ''" v-model="form.code" />
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]" />
                        </b-form-group>
                    </ValidationProvider>
                </div>
                <div class="col-12">
                    <ValidationProvider name="type" rules="" v-slot="{ errors }">
                        <b-form-group :label="$t('type')">
                            <multi-selectbox
                                v-model="form.type"
                                :multiple="false"
                                :options="typeOptions"
                                :validateError="errors[0]"></multi-selectbox>
                        </b-form-group>
                    </ValidationProvider>
                </div>
            </b-row>
        </ValidationObserver>

        <div class="col-12 mt-3 d-flex">
            <b-button @click="createForm" type="button" variant="primary" class="btn-lg mr-2">
                {{ $t("save") | toUpperCase }}
            </b-button>
        </div>
    </div>
</template>

<script>
//Other
import { ValidationProvider, ValidationObserver } from "vee-validate"
//Service
import ParameterService from "@/services/ParameterService";
// Components
import MultiSelectbox from "@/components/interactive-fields/MultiSelectbox"

export default {
    components: {
        ValidationProvider,
        ValidationObserver,
        MultiSelectbox
    },
    data() {
        return {
            formLoading: false,
            form: {
                code: null,
                name: null,
                name_en: null,
                type: null
            },

            typeOptions: [
                {
                    text: 'string',
                    value: 'string',
                },
                {
                    text: 'file',
                    value: 'file',
                },
            ]
        }
    },
    methods: {
        async createForm() {
            if (this.checkPermission('parameter_store')) {
                const isValid = await this.$refs.formModalValidate.validate()
                if (isValid) {
                    if (!this.form.type) {
                        this.form.type = 'string'
                    }
                    ParameterService.store(this.form)
                        .then((response) => {
                            this.$toast.success(this.$t("api." + response.data.message));
                            this.$emit("createFormSuccess")
                        })
                        .catch((error) => {
                            this.showErrors(error, this.$refs.formModalValidate)
                        });
                }
            } else {
                this.$toast.error(this.$t('you_are_not_authorized_for_this_operation'))
            }
        }
    }
}
</script>
